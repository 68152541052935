import { useCallback, useContext } from 'react';
import { CartContext } from 'contexts/cartContext';
import { AppContext } from 'contexts';
import { useRouter } from 'next/router';
import { CART_ACTIONS_TYPES } from 'constants/cart';
import Image from 'components/core/Image';
import Sticker from 'components/core/Sticker';
import { useTranslation } from 'next-i18next';
import { CURRENCY } from 'constants/';
import { Quantico } from 'next/font/google';
import classNames from 'classnames';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';

const quantico = Quantico({
  weight: '400',
  subsets: ['latin'],
});

interface CardProps extends Product.Item {
  url?: string;
  btn?: boolean;
}

const Card = (item: CardProps) => {
  const {
    btn, ...newItem
  } = item;
  const {
    discount,
    url,
    originPrice,
    assets: { image, flag: f },
    name,
    discountPrice,
    price,
    display,
    outOfStock,
  } = newItem;
  const flag = (Array.isArray(f) ? f[0] : f) as string;
  const { updateItem } = useContext(CartContext);
  const { currency } = useContext(AppContext);
  const { push } = useRouter();
  const { t } = useTranslation('card');

  const onClick = useCallback(() => {
    updateItem(newItem, 1, CART_ACTIONS_TYPES.ADD);
  }, [updateItem, newItem]);

  const handleClick = useCallback(() => {
    if (url) push(url).then(() => null).catch(() => null);
  }, [push, url]);

  return (
    <div className={styles.card}>
      { flag
        ? (
          <div className={styles.flag}>
            <Image
              unoptimized
              loader={() => flag}
              src={flag}
              alt='Card'
              width={32}
              height={32}
            />
          </div>
        ) : null }
      { discount ? <Sticker value={`${discount}%`} mTop={90} /> : null }
      <span className={styles.price}>
        {
            display || `$${originPrice / 100}`
        }
      </span>
      <Image
        onClick={handleClick}
        role='presentation'
        className={styles.img}
        loader={() => image}
        src={image}
        alt='Card'
        width={230}
        height={150}
        unoptimized
        style={{ width: '100%', height: 'auto' }}
      />
      <div className={styles.cardName}>
        {name}
        <div className={!outOfStock ? styles.inStock : styles.outOfStock}>
          {
            !outOfStock
              && (
              <FontAwesomeIcon
                style={{ width: '10px' }}
                icon={faCheck}
              />
              )
            }
          <span className={styles.inStockStatus}>
            {!outOfStock ? 'In Stock' : 'Out of stock'}
          </span>
        </div>
      </div>
      <div className={styles.cardInfo}>
        <div className={styles.priceWrap}>
          <span className={classNames(styles.newPrice, quantico.className)}>
            {CURRENCY[currency].sign}
            {discountPrice || price.value}
          </span>
          { discountPrice && (
            <div className={classNames(styles.discount, quantico.className)}>
              {CURRENCY[currency].sign}
              {price.value}
            </div>
          ) }
        </div>
        {
          (btn && !outOfStock) ? (
            <button
              type='button'
              className={styles.button}
              onClick={() => onClick()}
            >
              {t('plusAddToCard')}
            </button>
          )
            : null
        }
      </div>
    </div>
  );
};

export default Card;
