import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getStorage, setStorage } from 'services/localStorage.service';
import { NOTE } from 'constants/cart';
import { useTranslation } from 'next-i18next';
import styles from './index.module.scss';

const EmailDeliveryNote = () => {
  const [storageNote, setStorageNote] = useState(undefined);
  const { t } = useTranslation('layout');

  useEffect(() => {
    setStorageNote(getStorage(NOTE) || false);
  }, []);

  return (
    <div className={styles.container}>
      {
        (storageNote !== undefined && !storageNote)
          ? (
            <div className={styles.noteWrap}>
              <div className={styles.note}>
                <div className={styles.text}>
                  {t('emailDeliveryNote')}
                </div>
                <FontAwesomeIcon
                  onClick={() => {
                    setStorageNote(true);
                    setStorage(NOTE, true);
                  }}
                  className={styles.closeNote}
                  style={{ fontSize: 18 }}
                  icon={faXmark}
                />
              </div>
            </div>
          )
          : null
      }
    </div>
  );
};
export default EmailDeliveryNote;
