import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Button from 'components/core/Button';
import Link from 'next/link';

import { acceptTerms } from 'services/auth.service';
import Modal from 'components/core/Modal';
import { APP_URLS } from 'constants/index';
import { QueryKey } from 'hooks/api/useMe';

import styles from './index.module.scss';

export interface TermsModalProps {
  visible: boolean;
  onConfirm: () => void;
}

const TermsModal = ({ visible, onConfirm }: TermsModalProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync: accept } = useMutation(acceptTerms);
  const handleAcceptTerms = useCallback(() => {
    void accept().then(() => {
      queryClient.setQueryData([QueryKey], (user: User) => ({ ...user, terms: true }));
      onConfirm();
    });
  }, [accept, onConfirm, queryClient]);

  const [disabled, setDisabled] = useState(true);

  return (
    <Modal visible={visible} headerDisabled onClose={() => null}>
      <div className={styles.wrapper}>
        <div className={styles.center}>
          <h1>One last step...</h1>
          <p>
            <input type='checkbox' onChange={(e) => setDisabled(e.target.checked)} />
            I agree with the
            {' '}
            <Link href={APP_URLS.TERMS} className={styles.link}>Terms</Link>
            {' '}
            and
            {' '}
            <Link href={APP_URLS.PRIVACY} className={styles.link}>Privacy policy</Link>
          </p>
          <Button
            className={styles.submitButton}
            onClick={!disabled ? undefined : handleAcceptTerms}
            title='Submit'
            disabled={!disabled}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TermsModal;
