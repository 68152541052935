import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'contexts';
import { CartContext } from 'contexts/cartContext';
import { NoticeContext } from 'contexts/notification';
import { AxiosResponse } from 'axios';

import useAuth, { GoogleResponse } from '../api/useAuth';
import useUserUpdate from '../api/useUserUpdate';

const getParameterByName = (data: string, url: string) => {
  let name = data;
  name = name.replace(/[/]/, '[').replace(/[/]/, ']');
  const regex = new RegExp(`[\\?&#]${name}=([^&#]*)`);
  const results = regex.exec(url);
  return results == null ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const useOauth = () => {
  const [googleToken, setGoogleToken] = useState<string>();
  const { google } = useAuth();
  const { upsertUser, user } = useContext(AppContext);
  const { setCart } = useContext(CartContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { addUser } = useUserUpdate();
  const { message } = useContext(NoticeContext);

  const router = useRouter();
  const splitter = 'access_token=';

  useEffect(() => {
    if (router.asPath.includes(splitter)) {
      setLoading(true);
      const token = getParameterByName('access_token', router.asPath);
      setGoogleToken(token);
      google(token).then(async (result: AxiosResponse<GoogleResponse, Error>) => {
        addUser(result.data.user);
        upsertUser(result.data.user);
        setLoading(false);
        await router.replace('/');
      }).catch(async () => {
        message('Failed to log in', 'error');
        await router.push('/login');
        setLoading(false);
      });
    }
  }, [google, router, setCart, upsertUser, setLoading, addUser, message]);

  return { googleToken, loading, user };
};

export default useOauth;
