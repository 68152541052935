import { ReactNode } from 'react';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';

const Empty = ({ children, title }: { children?: ReactNode, title?: string }) => (
  <div className={styles.container}>
    {
      title
        ? (
          <div className={styles.wrap}>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
            />
            <h5 className={styles.emptyTitle}>{title}</h5>
          </div>
        )
        : children
    }
  </div>
);

export default Empty;
